<script setup>
// layout
import Layout from "../../layouts/main.vue";

// import thư viện
import mushroom from "cem-primary-api";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import { FormInstance } from "element-plus";
import toastr from "toastr";

// data khai báo
import MethodService from "../../service/MethodService";
import Data from "./dataFormQuality";
const ruleFormRef = ref(FormInstance);
const dataForm = reactive({
  value: MethodService.copyObject(Data.dataForm),
});
const loadingBtn = ref(false);
const checkDisabled = reactive([{
  value: true,
  name: 'Có',
}, {
  value: false,
  name: 'Không',
}]);

// pinia store
import { onMounted, reactive } from "vue";

const router = useRoute();
const routerPush = useRouter();

// validate form
import Validate from "../../service/ValidService";
import { langWeb } from "../../stores/lang";
import LanguageService from "../../service/LanguageService";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());

const rulesForm = reactive({
  level: [Validate.required],
  threshold_name: [Validate.required],
  lower_threshold: [Validate.required],
  higher_threshold: [Validate.required],
  disabled: [Validate.required],
});

const getQualityLevel = async () => {
    if(router.query.id) {
        try {
            const response = await mushroom.quality_level.findByIdAsync({
                id: router.query.id, // required
            });
            console.log("Thông tin quality level trả về: %o", response);
            dataForm.value = response.result;
        }
        catch (e) {
            console.error("Có lỗi: %o", e);
        }
    }
}

const addNewQuality = async (formEl) => {
    if (!formEl) return;
    formEl.validate(async (valid) => {
        loadingBtn.value = true;
        if (valid) {
            const quality_level_object = {
              level: dataForm.value.level,
              threshold_name: dataForm.value.threshold_name,
              lower_threshold: dataForm.value.lower_threshold,
              higher_threshold: dataForm.value.higher_threshold,
              note: dataForm.value.note,
              disabled: dataForm.value.disabled,
            };
            try {
                const newId = await mushroom.quality_level.createAsync(quality_level_object)
                console.log("Thêm mới thành công, new id: %o", newId);
                if (newId) {
                    toastr.success(LanguageService[lang.value]?.toastr_add_threshold_success ?? "toastr_add_threshold_success");
                }
                routerPush.go(-1);
            } catch (error) {
                // if (error.meta.field === "$unique_key") {
                //     toastr.error("Tên ngưỡng đã tồn tại!");
                // }
                toastr.error(LanguageService[lang.value]?.toastr_add_threshold_fail ?? "toastr_add_threshold_fail");
                console.error("Có lỗi: %o", error);
            } finally {
                setTimeout(() => {
                loadingBtn.value = false;
                }, 2000);
            }
        } else {
            loadingBtn.value = false;
            return false;
        }})
};

const updateQuality = async (formEl) => {
    if (!formEl) return;
    formEl.validate(async (valid) => {
        loadingBtn.value = true;
        if (valid) {
            const quality_level_object = {
              id: router.query.id,
              level: dataForm.value.level,
              threshold_name: dataForm.value.threshold_name,
              lower_threshold: dataForm.value.lower_threshold,
              higher_threshold: dataForm.value.higher_threshold,
              note: dataForm.value.note,
              disabled: dataForm.value.disabled,
            };

            try {
                const response = await mushroom.quality_level.partialUpdateAsync(quality_level_object);
                if (response.result == router.query.id) {
                    toastr.success(LanguageService[lang.value]?.toastr_edit_threshold_success ?? "toastr_edit_threshold_success");
                } else
                    console.log("Dữ liệu không thay đổi");
                routerPush.go(-1);
            } catch (error) {
                console.error("Có lỗi: %o", error);
                toastr.error(LanguageService[lang.value]?.toastr_edit_threshold_fail ?? "toastr_edit_threshold_fail");
            } finally {
                setTimeout(() => {
                loadingBtn.value = false;
                }, 2000);
            }
        } else {
            loadingBtn.value = false;
            return false;
        }
    })
}

onMounted(() => {
  if (router.name === "UpdateQuality") {
    getQualityLevel();
  }
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 v-if="router.name === 'CreateQuality'">
          Thêm mới ngưỡng
        </h3>
        <h3 v-else>Chỉnh sửa ngưỡng</h3>
      </div>
      <div class="card-body">
        <div class="main-body">
          <el-form
            ref="ruleFormRef"
            :model="dataForm.value"
            status-icon
            :rules="rulesForm"
            label-width="200px"
            class="form-user"
          >
            <el-form-item label="Level" prop="level">
              <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.level"
                @change="dataForm.value.level = dataForm.value.level.trim()"
                placeholder="Nhập level"
              />
              <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.level"
                @change="dataForm.value.level = dataForm.value.level.trim()"
                placeholder="Nhập level"
              />
              <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.level"
                @change="dataForm.value.level = dataForm.value.level.trim()"
                placeholder="Nhập level"
              />
            </el-form-item>

            <el-form-item label="Tên ngưỡng" prop="threshold_name">
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.threshold_name"
                @change="dataForm.value.threshold_name = dataForm.value.threshold_name.trim()"
                placeholder="Nhập tên ngưỡng"
              />
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.threshold_name"
                @change="dataForm.value.threshold_name = dataForm.value.threshold_name.trim()"
                placeholder="Nhập tên ngưỡng"
              />
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.threshold_name"
                @change="dataForm.value.threshold_name = dataForm.value.threshold_name.trim()"
                placeholder="Nhập tên ngưỡng"
              />
            </el-form-item>

            <el-form-item label="Mức dưới" prop="lower_threshold">
              <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.lower_threshold"
                @change="dataForm.value.lower_threshold = dataForm.value.lower_threshold.trim()"
                placeholder="Nhập mức dưới"
              />
              <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.lower_threshold"
                @change="dataForm.value.lower_threshold = dataForm.value.lower_threshold.trim()"
                placeholder="Nhập mức dưới"
              />
              <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.lower_threshold"
                @change="dataForm.value.lower_threshold = dataForm.value.lower_threshold.trim()"
                placeholder="Nhập mức dưới"
              />
            </el-form-item>

            <el-form-item label="Mức trên" prop="higher_threshold">
              <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.higher_threshold"
                @change="dataForm.value.higher_threshold = dataForm.value.higher_threshold.trim()"
                placeholder="Nhập mức trên"
              />
              <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.higher_threshold"
                @change="dataForm.value.higher_threshold = dataForm.value.higher_threshold.trim()"
                placeholder="Nhập mức trên"
              />
              <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.higher_threshold"
                @change="dataForm.value.higher_threshold = dataForm.value.higher_threshold.trim()"
                placeholder="Nhập mức trên"
              />
            </el-form-item>

            <el-form-item label="Ghi chú" prop="note">
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.note"
                @change="dataForm.value.note = dataForm.value.note.trim()"
                placeholder="Nhập ghi chú"
              />
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.note"
                @change="dataForm.value.note = dataForm.value.note.trim()"
                placeholder="Nhập ghi chú"
              />
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.note"
                @change="dataForm.value.note = dataForm.value.note.trim()"
                placeholder="Nhập ghi chú"
              />
            </el-form-item>

            <el-form-item
              label="Vô hiệu hóa"
              prop="disabled"
            >
              <el-select
                v-model="dataForm.value.disabled"
                
                clearable
                placeholder="Bạn muốn vô hiệu hóa không?"
                style="width: 100%"
              >
                <el-option
                  v-for="item in checkDisabled"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <el-select
                v-model="dataForm.value.disabled"
                
                clearable
                placeholder="Bạn muốn vô hiệu hóa không?"
                style="width: 100%"
              >
                <el-option
                  v-for="item in checkDisabled"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <el-select
                v-model="dataForm.value.disabled"
                
                clearable
                placeholder="Bạn muốn vô hiệu hóa không?"
                style="width: 100%"
              >
                <el-option
                  v-for="item in checkDisabled"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <div class="text-center">
              <button
                type="button"
                class="btn btn-soft-secondary btn-border mx-3"
                @click="routerPush.go(-1)"
              >
                {{ $t("t-back") }}
              </button>
              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="addNewQuality(ruleFormRef)"
                v-if="router.name === 'CreateQuality'"
                :loading="loadingBtn"
              >
                {{ $t("t-add") }}
              </el-button>

              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="updateQuality(ruleFormRef)"
                :loading="loadingBtn"
                v-else
              >
                {{ $t("t-update") }}
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.el-radio {
  margin-bottom: 0;
}
.form-user {
    display: flex;
    justify-content: flex-start;
}
</style>
